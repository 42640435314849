import React from 'react';
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/promotional.css'
import CounterArea from '../CounterArea/CounterArea';

const PromotionalView = () => {
    return (
        <section id="features" className="features-area">
            <div className="section-title text-center mb-70">
                <h2 className="title2">Warum Solltest Du MyFutureTokens Kaufen?
                </h2>
            </div>

            <Container>
                <Row className="justify-content-center">
                    <Col lg={2} md={4} sm={6} className="text-center feature-box">
                        <div className="feature-icon">
                            <i className="fas fa-shield-alt"></i>
                        </div>
                        <h4 className="feature-title">Sicherheit und Transparenz</h4>
                        <p className="feature-description">
                            MFT Token bietet höchste Sicherheit und Transparenz durch die Unterstützung der Blockchain-Technologie. Alle Ihre Transaktionen sind geschützt.
                        </p>
                    </Col>
                    <Col lg={2} md={4} sm={6} className="text-center feature-box">
                        <div className="feature-icon">
                            <i className="fas fa-exchange-alt"></i>
                        </div>
                        <h4 className="feature-title">Schnelle und kostengünstige Transaktionen</h4>
                        <p className="feature-description">
                            MFT Token bietet seinen Nutzern durch niedrige Transaktionsgebühren und hohe Transaktionsgeschwindigkeit schnelle und kostengünstige Transfers.
                        </p>
                    </Col>
                    <Col lg={2} md={4} sm={6} className="text-center feature-box">
                        <div className="feature-icon">
                            <i className="fas fa-globe"></i>
                        </div>
                        <h4 className="feature-title">Globale Reichweite</h4>
                        <p className="feature-description">
                            MFT Token ist weltweit zugänglich und bietet unbegrenzte Handelsmöglichkeiten und Investitionschancen.
                        </p>
                    </Col>
                    <Col lg={2} md={4} sm={6} className="text-center feature-box">
                        <div className="feature-icon">
                            <i className="fas fa-chart-line"></i>
                        </div>
                        <h4 className="feature-title">Hohe Renditemöglichkeiten</h4>
                        <p className="feature-description">
                            In der schnell wachsenden Welt der Blockchain-Technologie bietet MFT Token Investoren hohe Renditemöglichkeiten durch innovative Projekte und Partnerschaften.
                        </p>
                    </Col>
                    {/* <Col lg={2} md={4} sm={6} className="text-center feature-box">
                        <div className="feature-icon">
                            <i className="fas fa-lock"></i>
                        </div>
                        <h4 className="feature-title">Globale Zahlungen und Überweisungen</h4>
                        <p className="feature-description">
                            MFC Token bietet eine ideale Lösung für kostengünstige und schnelle internationale Zahlungen und Überweisungen.
                        </p>
                    </Col> */}
                </Row>
                <Row></Row>
            </Container>
        </section>

    );
};

export default PromotionalView;
