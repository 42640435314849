import React from "react";
import aboutImg from "../../assets/img/logo/MFC-logo.png";
import Parse from 'parse';
import { useEffect, useState } from "react";

const AboutInfo = () => {
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");

  useEffect(() => {
    // Parse sorgusu ile myfacecoin objesinden aboutTitle ve aboutDescription çekme
    const MyFaceCoin = Parse.Object.extend("myfacecoin");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setAboutTitle(object.get("aboutTitle"));
        setAboutDescription(object.get("aboutDescription"));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);

  return (
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <img src={aboutImg} alt="About Image" style={{ marginBottom: '20px', width: '300px' }} />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-content-two text-center wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title section-title-two mb-15">
                <h2 className="title" style={{ textAlign: 'center' }}>
                  {aboutTitle}
                </h2>
              </div>
              <p className="about-description">
                {aboutDescription}
              </p>
              <div style={{ textAlign: 'center' }}>
                <a
                  href="https://www.myfuturetoken.com/MFT-WhitePaper-GER.pdf"
                  className="btn btn-two"
                  style={{ display: 'inline-block', marginTop: '20px' }}
                >
                  Dokument herunterladen
                  {/* https://www.myfuturetoken.com/MFT-WhitePaper-GER.pdf */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfo;
