import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import guidelineImg from "../../assets/img/banner/howto.png";
import Step from "./Step";
import metamask1 from "../../assets/img/metamaskguideline/metamask1.jpg";
import metamask2 from "../../assets/img/metamaskguideline/metamask2.jpg";
import metamask3 from "../../assets/img/metamaskguideline/metamask3.jpg";
import metamask5 from "../../assets/img/metamaskguideline/metamask5.jpg";
import metamaskpassword from "../../assets/img/metamaskguideline/metamaskpassword.jpg";
import metamask6 from "../../assets/img/metamaskguideline/metamask6.jpg";
import metamaskBackupVerify from "../../assets/img/metamaskguideline/metamaskBackupVerify.jpg";
import metamask7 from "../../assets/img/metamaskguideline/metamask7.jpg";

const steps = [
  {
    title: "MetaMask herunterladen",
    description: "Besuchen Sie zuerst <a href='https://metamask.io/' target='_blank'>MetaMask</a> und klicken Sie auf 'Download'. Klicken Sie dann auf 'Install MetaMask for Chrome'.",
    image: metamask1,
  },
  {
    title: "MetaMask installieren",
    description: "Sie werden zum Chrome Web Store weitergeleitet. Klicken Sie auf 'Zu Chrome hinzufügen' und dann auf 'Erweiterung hinzufügen', um die MetaMask-Erweiterung zu installieren.",
    image: metamask2,
  },
  {
    title: "MetaMask-Erweiterung aufrufen",
    description: "Nach der Installation finden Sie die Erweiterung, indem Sie auf das Puzzleteil-Symbol in der oberen rechten Ecke Ihres Browsers klicken. Heften Sie die Erweiterung für einen einfacheren Zugriff an.",
    image: metamask3,
  },
  {
    title: "Eine Wallet erstellen",
    description: "Öffnen Sie die MetaMask-Erweiterung und wählen Sie 'Create a Wallet'. Wenn Sie keine Analysedaten teilen möchten, klicken Sie auf 'No Thanks'.",
    image: metamask5,
  },
  {
    title: "Ein Passwort erstellen",
    description: "Erstellen Sie ein Passwort, um die MetaMask-Erweiterung auf Ihrem Computer zu sperren. Geben Sie Ihr Passwort ein und klicken Sie auf 'Create'.",
    image: metamaskpassword,
  },
  {
    title: "Geheime Sicherungsphrase",
    description: "Sehen Sie sich das Informationsvideo an, wie MetaMask funktioniert. Dann sehen Sie Ihre geheime Sicherungsphrase. Bewahren Sie sie an einem sicheren Ort auf.",
    image: metamask6,
  },
  {
    title: "Geheime Sicherungsphrase sichern und überprüfen",
    description: "Sichern Sie Ihre geheime Sicherungsphrase und überprüfen Sie sie auf dem nächsten Bildschirm. Wenn Sie diese Phrase verlieren, verlieren Sie den Zugriff auf Ihre Wallet. Wenn jemand anderes sie bekommt, kann er auf Ihre Wallet zugreifen.",
    image: metamaskBackupVerify,
  },
  {
    title: "Ihre Ethereum-Adresse finden",
    description: "Öffnen Sie Ihre Wallet, indem Sie auf das Fuchs-Symbol in der oberen rechten Ecke klicken. Kopieren Sie die '0x...' Adresse, um Ihre Ethereum-Adresse zu finden.",
    image: metamask7,
  },
];

const Guideline = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const openModal = () => {
    setCurrentStep(0); // Modal her açıldığında baştan başlat
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <section id="guideline" className="guideline-area" style={{ marginBottom: '100px' }}>
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <img src={guidelineImg} alt="Guideline Image" style={{ marginBottom: '20px', width: '500px' }} />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="guideline-content text-center wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title section-title-two mb-15">
                <h2 className="title" style={{ textAlign: 'center', marginBottom: '20px' }}>
                  MetaMask Installationsanleitung
                </h2>
                <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                  Hatten Sie noch nie eine digitale Geldbörse? Klicken Sie hier, um zu erfahren, wie Sie eine erhalten.
                </p>
              </div>
              <Button onClick={openModal} variant="primary" style={{ display: 'inline-block', marginBottom: '100px' }}>
                Anleitung anzeigen
              </Button>
              <Modal show={modalIsOpen} onHide={closeModal} dialogClassName="guideline-modal" size="xl">
                <Modal.Body>
                  <Step
                    stepNumber={currentStep + 1}
                    title={steps[currentStep].title}
                    description={steps[currentStep].description}
                    image={steps[currentStep].image}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    isLastStep={currentStep === steps.length - 1}
                    onClose={handleClose}
                  />
                </Modal.Body>
                {/* <Modal.Footer className="justify-content-center">
                  <Button variant="secondary" onClick={closeModal}>
                    Schließen
                  </Button>
                </Modal.Footer> */}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guideline;