import React from "react";
import PropTypes from "prop-types";
import "./Step.css";

const Step = ({ stepNumber, title, description, image, onNext, onPrevious, isLastStep, onClose }) => {
  return (
    <div className="step-container">
      <h2 className="step-main-title">Wie man eine MetaMask-Wallet bekommt</h2>
      <div className="step-header">
        {/* <div className="step-number">{stepNumber}</div> */}
      </div>
      <img src={image} alt={`Step ${stepNumber}`} className="step-image" />
      <h4 className="step-subtitle">{title}</h4>
      <p className="step-description" dangerouslySetInnerHTML={{ __html: description }}></p>
      <div className="step-buttons">
        {stepNumber > 1 && (
          <button onClick={onPrevious} className="step-button">
            Previous
          </button>
        )}
        {!isLastStep ? (
          <button onClick={onNext} className="step-button">
            Next
          </button>
        ) : (
          <button onClick={onClose} className="step-button">
            Sie sind jetzt bereit, MFT zu kaufen
          </button>
        )}
      </div>
    </div>
  );
};

Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Step;