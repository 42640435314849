import React from "react";
import aboutImg from "../../assets/img/banner/MFC-Connect-Buy-Earn-GER.png";
import Parse from 'parse';
import { useEffect, useState } from "react";

const AboutInfo = () => {
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");

  useEffect(() => {
    // Parse sorgusu ile myfacecoin objesinden aboutTitle ve aboutDescription çekme
    const MyFaceCoin = Parse.Object.extend("myfacecoin");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setAboutTitle(object.get("aboutTitle"));
        setAboutDescription(object.get("aboutDescription"));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);

  return (
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <img src={aboutImg} alt="About Image" style={{ marginBottom: '20px' , width: '800px' }} />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-content-two text-center wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title section-title-two mb-15">
                <h2 className="title" style={{  textAlign: 'center' }}>
                <li>Kaufen Sie MFT zum ersten Mal,</li> 
                <li>10 % MFT als Bonus!</li>
                </h2>
              </div>
              <p className="about-description">
              Wenn Sie zum ersten Mal Ihre Wallet verbinden und MFT-Token kaufen, schenken wir Ihnen 10 % der gekauften MFT-Token als Bonus. Dies ist unser Willkommensgeschenk für neue Benutzer, die die Welt der MFT-Token entdecken möchten. Verbinden Sie einfach Ihre Wallet und führen Sie Ihren ersten Kauf durch, um von diesem exklusiven Angebot zu profitieren.
              </p>
              <div style={{ textAlign: 'center' }}>
                <a
                  href="#register"
                  className="btn btn-one"
                  style={{ display: 'inline-block', marginTop: '20px' }}
                >
                  Lasst uns beginnen
                </a>
              </div>
            </div>          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfo;
