import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Container, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/CrptoCSS.css';

const symbolMapping = {
  'BTCUSDT': 'Bitcoin',
  'ETHUSDT': 'Ethereum',
  'BNBUSDT': 'BNB',
  'SOLUSDT': 'Solana',
  'USDTUSDT': 'Tether'
};

const BinanceMarketCap = () => {
  const [coins, setCoins] = useState([]);
  const ws = useRef(null);
  const timerRef = useRef(null);

  const updateCoinData = useCallback((data) => {
    setCoins((prevCoins) => {
      const updatedCoins = [...prevCoins];
      const index = updatedCoins.findIndex(coin => coin.symbol === data.s);
      const price = parseFloat(data.c);
      const volume = parseFloat(data.q);
      const marketCap = price * parseFloat(data.v);
      const change = parseFloat(data.P);
      const changeFormatted = change > 0 ? `+${change.toFixed(2)}` : `${change.toFixed(2)}`;

      if (index !== -1) {
        updatedCoins[index] = {
          ...updatedCoins[index],
          price: price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          volume: volume.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          marketCap: marketCap.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          change: changeFormatted,
        };
      } else {
        updatedCoins.push({
          symbol: data.s,
          name: symbolMapping[data.s] || data.s,
          price: price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          volume: volume.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          marketCap: marketCap.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          change: changeFormatted,
        });
      }
      return updatedCoins.sort((a, b) => parseFloat(b.price.replace(/[^0-9.-]+/g,"")) - parseFloat(a.price.replace(/[^0-9.-]+/g,"")));
    });
  }, []);

  const setupWebSocket = useCallback(() => {
    if (ws.current) {
      ws.current.close();
    }

    ws.current = new WebSocket('wss://stream.binance.com:9443/ws');

    ws.current.onopen = () => {
      if (ws.current) {
        ws.current.send(JSON.stringify({
          method: 'SUBSCRIBE',
          params: ['btcusdt@ticker', 'ethusdt@ticker', 'bnbusdt@ticker', 'solusdt@ticker', 'usdtusdt@ticker'],
          id: 1
        }));
      }
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.e === '24hrTicker') {
        updateCoinData(data);
      }
    };
  }, [updateCoinData]);

  useEffect(() => {
    setupWebSocket();

    timerRef.current = setInterval(() => {
      setupWebSocket();
    }, 10000); // Refresh WebSocket every 10 seconds

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [setupWebSocket]);

  const memoizedCoins = useMemo(() => coins, [coins]);

  return (
    <Container className="crypto-container">
      <h1 className="crypto-title">Crypto Market Cap</h1>
      <Table hover className="crypto-table" style={{ tableLayout: 'fixed' }}>
        <thead style={{ backgroundColor: '#f57c00', color: 'white' }}>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Price</th>
            <th>24h Change</th>
            <th>Market Cap</th>
            <th>Volume(24h)</th>
          </tr>
        </thead>
        <tbody>
          {memoizedCoins.map((coin, index) => (
            <tr key={coin.symbol}>
              <td>{index + 1}</td>
              <td>{coin.name} <span className="symbol">{coin.symbol.replace('USDT', '')}</span></td>
              <td>{coin.price}</td>
              <td style={{ color: coin.change.startsWith('+') ? 'green' : 'red' }}>{coin.change}%</td>
              <td>${coin.marketCap}</td>
              <td>${coin.volume}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="refresh-timer">Refresh: 10 second</div>
    </Container>
  );
};

export default BinanceMarketCap;