import React, { useState } from "react";
import Parse from 'parse';

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateEmail(email)) {
      setMessage("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return;
    }

    try {
      const EmailSubscription = Parse.Object.extend("EmailSubscription");
      const subscription = new EmailSubscription();
      subscription.set("email", email);

      await subscription.save();
      setMessage("Vielen Dank für Ihre Anmeldung!");
      setEmail("");
    } catch (error) {
      console.error("Error saving email to Parse:", error);
      setMessage("Es gab ein Problem bei der Anmeldung. Bitte versuchen Sie es später erneut.");
    }
  };

  return (
    <section className="newsletter-area">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h2 className="title">Newsletter abonnieren</h2>
              </div>
              <div className="newsletter-form">
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Geben sie ihre E-Mail Adresse ein"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className="btn btn-two">
                    Abonnieren
                  </button>
                </form>
              </div>
              {message && <div className="message">{message}</div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;