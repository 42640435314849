import React from "react";
import About from "../../components/About/About";
import AboutInfo2 from "../../components/About/AboutInfo2";
import Crypto from "../../components/About/Crypto";

import AboutInfo from "../../components/About/AboutInfo";
import AboutInfoRewards from "../../components/About/AboutInfoRewards";

import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import LayoutTwo from "../../layouts/LayoutTwo";
import Sales from "../../components/Sales/Sales";
import NetworkMarketing from "../../components/About/NetworkMarketing";
import PromotionalView from "../../components/About/Promotional";
import BuyTokenView from "../../components/About/BuyTokenView";
import TokenomicsBanner from "../../components/About/TokenomicsBanner";
import SalesBar from "../../components/About/SalesBar";
import MFTStory from "../../components/About/MFTStory";
import BinanceMarketCap from "../../components/About/BinanceMarketCap";
import Guideline from "../../components/Step/Guideline";

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useInvitation } from '../../context/InvitationContext';
import Parse from 'parse';

const HomeTwo = () => {
  const { inviteCode } = useParams();
  const { setIsInvited, setInvitationCode, setInviterName } = useInvitation();

  useEffect(() => {
    if (inviteCode) {
      const checkInvitation = async () => {
        try {
          // NetworkMarketing tablosundan davet eden kişiyi bul
          const NetworkMarketing = Parse.Object.extend("NetworkMarketing");
          const query = new Parse.Query(NetworkMarketing);
          query.equalTo("inviteCode", inviteCode);
          const inviter = await query.first();

          if (inviter) {
            // Davet eden kişinin adı ve soyadının ilk harfini birleştir
            const fullName = `${inviter.get("name")} ${inviter.get("surname").charAt(0)}.`;
            setInviterName(fullName);
            setIsInvited(true);
            setInvitationCode(inviteCode);

            // Ziyareti kaydet
            const Invitation = Parse.Object.extend("Invitation");
            const invitation = new Invitation();
            invitation.set("inviteCode", inviteCode);
            invitation.set("visitDate", new Date());
            await invitation.save();
          }
        } catch (error) {
          console.error("Davet kodu kontrolünde hata:", error);
        }
      };
      
      checkInvitation();
    }
  }, [inviteCode]);



  return (
    <LayoutTwo>
      <main>
        <BannerTwo />
        <AboutInfo2 />
        <CountDownTwo />

        <AboutInfo />
        <Guideline />

        <AboutInfoRewards />
        <NetworkMarketing />
        <PromotionalView />
        <SalesBar />

        <TokenomicsBanner />
        <MFTStory />

        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}

        <Sales />
        <RoadmapTwo />
        {/* <Crypto /> */}
        <BinanceMarketCap />

        <Faq />
        <ContactTwo />
        <Newsletter />
      </main>
    </LayoutTwo>
  );
};

export default HomeTwo;
