import React, { useEffect, useRef } from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";
import "./RoadmapTwo.css"; // Yeni CSS dosyası

const RoadmapTwo = () => {

  const scrollRef = useRef(null);



  const roadmap_items = [
    {
      roadmapTitle: "Q3 2023",
      title: "Konzeptentwicklung und strategische Planung",
      info: (
        <>
          Projektplanung und -konzeption abgeschlossen. <br />
          Wertschöpfung und Vision für MFT definiert. <br />
          Erste Teambildung und Strategieentwicklung.
        </>
      ),
    },
    {
      roadmapTitle: "Q4 2023",
      title: "Technische Infrastruktur und UX-Design",
      info: (
        <>
          Entwicklung der technischen Infrastruktur. <br />
          UX-Design und Benutzerfreundlichkeit optimiert. <br />
          Sicherheitsmaßnahmen implementiert und getestet.
        </>
      ),
    },
    {
      roadmapTitle: "Q1 2024",
      title: "Technologie- und Sicherheitsverbesserungen",
      info: (
        <>
          Verbesserung der Technologie und Sicherheitsprotokolle. <br />
          Weiterentwicklung der Infrastruktur. <br />
          Interne Tests und Optimierungen durchgeführt.
        </>
      ),
    },
    {
      roadmapTitle: "Q2 2024",
      title: "Marketing und Network-Marketing-System",
      info: (
        <>
          Entwicklung der Marketingstrategie. <br />
          Aufbau des Network-Marketing-Systems begonnen. <br />
          Vorbereitung für die öffentliche Einführung.
        </>
      ),
    },
    {
      roadmapTitle: "Q3 2024",
      title: "ICO-Vorbereitung und öffentliche Beta",
      info: (
        <>
          Vorbereitung für den ICO-Prozess. <br />
          Übergang von der Beta- zur öffentlichen Version. <br />
          Letzte Anpassungen vor dem offiziellen Start.
        </>
      ),
    },
    {
      roadmapTitle: "Q4 2024",
      title: "Vollständiger Start und Verkaufsziel",
      info: (
        <>
          Offizieller Start des Network-Marketing-Systems. <br />
          Ziel: 1.000.000$ Verkaufsvolumen erreichen. <br />
          Expansion und Wachstum des MFT-Ökosystems.
        </>
      ),
    },
    {
      roadmapTitle: "Q1 2025",
      title: "KI-Projekte und MFT-Token-Entwicklung",
      info: (
        <>
          Präsentationen von KI-Projekten. <br />
          KI-Entwicklungsprozesse. <br />
          MFT-Token KI-Verbesserungen und -Integrationen.
        </>
      ),
    }

  ];

  useEffect(() => {
    const scrollToCurrentQuarter = () => {
      if (scrollRef.current && scrollRef.current.children.length > 0) {
        const currentDate = new Date();
        const currentQuarter = Math.floor(currentDate.getMonth() / 3);
        const currentYear = currentDate.getFullYear();
        
        const targetIndex = roadmap_items.findIndex(item => {
          const [q, year] = item.roadmapTitle.split(' ');
          return parseInt(year) === currentYear && parseInt(q[1]) - 1 === currentQuarter;
        });

        if (targetIndex !== -1 && targetIndex < scrollRef.current.children.length) {
          const targetElement = scrollRef.current.children[targetIndex];
          if (targetElement && targetElement.scrollIntoView) {
            setTimeout(() => {
              targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }, 100);
          }
        }
      }
    };

    scrollToCurrentQuarter();
  }, [roadmap_items]);


  return (
    <section id="roadmap" className="roadmap-area-two pt-130 pb-100">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title section-title-two text-center mb-65">
              <span className="sub-title">Roadmap</span>
              <h2 className="title">
                MyFutureToken-Strategie und
                <br />
                projektplan
              </h2>
            </div>
          </div>
        </div>

        <div className="roadmap-container">
          <div className="roadmap-scroll" ref={scrollRef}>
            <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>
          </div>
          <div className="scroll-indicator">
            <span>⟶</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;

