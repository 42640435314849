import React, { useEffect, useState } from "react";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import Parse from "parse";
import BuyTokenWizard from "../About/BuyTokenView";
import './Sales.css';

const Sales = () => {
  const [mfcDescription, setmfcDescription] = useState("");
  const [mfcTokenDesc, setmfcTokenDesc] = useState("");
  const [tabOption1, settabOption1] = useState("");
  const [tabOption2, settabOption2] = useState("");
  const [ethPrice, setEthPrice] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const mftValue = 0.005; // USD

  useEffect(() => {
    // Parse verilerini çekme
    const MyFaceCoin = Parse.Object.extend("Sales");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setmfcDescription(object.get("mfcDescription"));
        setmfcTokenDesc(object.get("mfcTokenDesc"));
        settabOption1(object.get('tabOption1'));
        settabOption2(object.get('tabOption2'));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });

    // Binance API'den fiyatları çekme
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbols=["ETHUSDT","BNBUSDT"]');
        const data = await response.json();
        const ethPrice = data.find(item => item.symbol === "ETHUSDT").price;
        const bnbPrice = data.find(item => item.symbol === "BNBUSDT").price;
        setEthPrice(parseFloat(ethPrice));
        setBnbPrice(parseFloat(bnbPrice));
      } catch (error) {
        console.error("Fiyat çekme hatası:", error);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  const mftToEth = ethPrice ? (mftValue / ethPrice).toFixed(8) : "Lädt...";
  const mftToBnb = bnbPrice ? (mftValue / bnbPrice).toFixed(8) : "Lädt...";
  const ethToMft = ethPrice ? (ethPrice / mftValue).toFixed(2) : "Lädt...";
  const bnbToMft = bnbPrice ? (bnbPrice / mftValue).toFixed(2) : "Lädt...";

  const newDescription = "Investieren Sie mit MFT, der Investitionsoption der neuen Generation, in die Zukunft";

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <section id="sales" className="chart-area chart-bg">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-5">
              <div className="chart-content wow fadeInLeft" data-wow-delay=".2s">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <SalesTabButton
                    title="Token-Verkauf"
                    className="active"
                    id="funding-tab"
                    target="#funding"
                    ariaControls="funding"
                    ariaSelected={true}
                  />
                  <SalesTabButton
                    title="Token-Details"
                    className=""
                    id="token-tab"
                    target="#token"
                    ariaControls="token"
                    ariaSelected={false}
                  />
                </ul>

                <div className="tab-content" id="myTabContent">
                  <SalesTabContent
                    className={"show active"}
                    id="funding"
                    ariaLabel="funding-tab"
                    title={`1 MFT = $${mftValue} (${mftToEth} ETH / ${mftToBnb} BNB)`}
                    description={newDescription}
                    link="/"
                    buttonText="Jetzt Kaufen"
                    onButtonClick={handleOpenModal}
                  />

                  <SalesTabContent
                    className={""}
                    id="token"
                    ariaLabel="token-tab"
                    title="Token-Umrechnungen"
                    description={`1 ETH = ${ethToMft} MFT\n1 BNB = ${bnbToMft} MFT`}
                    link="/"
                    buttonText=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <BuyTokenWizard onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Sales;