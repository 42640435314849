import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Presale', value: 20, color: '#FF9900', amount: '2.000.000.000' },  // 2 milyar
  { name: 'Staking', value: 25, color: '#FF6600', amount: '2.500.000.000' },  // 2.5 milyar
  { name: 'Bonusverteilung', value: 5, color: '#FF3300', amount: '500.000.000' },  // 500 milyon
  { name: 'Marketing', value: 15, color: '#FFCC00', amount: '1.500.000.000' },  // 1.5 milyar
  { name: 'Projektfonds', value: 20, color: '#FF0066', amount: '2.000.000.000' },  // 2 milyar
  { name: 'Liquidität', value: 15, color: '#CC00FF', amount: '1.500.000.000' }   // 1.5 milyar
];

const TokenomicsBanner = () => {
  return (
    <section id="tokenomics" className="tokenomics-area" style={{ margin: '80px 0' }}>
      <div className="container custom-container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="section-title section-title-two mb-15 text-center" style={{ marginBottom: '40px' }}>
              <h2 className="title">Tokenomics</h2>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="text-center">
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={150} label>
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="tokenomics-content text-center wow fadeInRight" data-wow-delay=".2s">
              <div className="tokenomics-details" style={styles.tokenomicsDetails}>
                {data.map((entry, index) => (
                  <div className="tokenomics-item" style={{ ...styles.tokenomicsItem, borderColor: entry.color }} key={index}>
                    <h4 style={{ ...styles.itemTitle, color: entry.color }}>{entry.name}</h4>
                    <p style={{ ...styles.itemPercentage, color: entry.color }}>{entry.value}%</p>
                    <p style={styles.itemAmount}>{entry.amount}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  tokenomicsDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  tokenomicsItem: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '48%',
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  itemTitle: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  itemPercentage: {
    textAlign: 'center'
  },
  itemAmount: {
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center'
  }
};

export default TokenomicsBanner;
