import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Parse from "parse";
const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 20px;
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
  background: linear-gradient(to right, #00008B, #ADD8E6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin: 20px 0;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
`;

const SuccessMessage = styled(Title)`
  margin-top: 20px;
`;

const countryList = [
    "Belgien", "Bulgarien", "Dänemark", "Deutschland", "Estland", "Finnland", "Frankreich",
    "Griechenland", "Irland", "Italien", "Kroatien", "Lettland", "Litauen", "Luxemburg",
    "Malta", "Niederlande", "Österreich", "Polen", "Portugal", "Rumänien", "Schweden",
    "Slowakei", "Slowenien", "Spanien", "Tschechien", "Ungarn", "Vereinigtes Königreich", "Zypern",
    // Diğer Avrupa ülkeleri
    "Norwegen", "Schweiz", "Island", "Liechtenstein", "Monaco", "San Marino", "Vatikanstadt",
    // Amerika ülkeleri
    "Vereinigte Staaten", "Kanada", "Mexiko", "Brasilien", "Argentinien", "Chile", "Kolumbien", "Peru",
    // Diğer önemli ülkeler
    "Australien", "China", "Indien", "Japan", "Russland", "Südafrika", "Türkei",
    // Genel seçenek
    "Andere"
];

const NetworkMarketingModal = ({ show, onHide }) => {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        country: ''
    });

    useEffect(() => {
        if (!show) {
            setSubmitted(false);
            setFormData({
                name: '',
                surname: '',
                email: '',
                phone: '',
                country: ''
            });
        }
    }, [show]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            phone: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Parse'a kayıt işlemi
            const NetworkMarketing = Parse.Object.extend("NetworkMarketing");
            const networkMarketing = new NetworkMarketing();

            networkMarketing.set("name", formData.name);
            networkMarketing.set("surname", formData.surname);
            networkMarketing.set("email", formData.email);
            networkMarketing.set("phone", formData.phone);
            networkMarketing.set("country", formData.country);
            networkMarketing.set("status", "pending");

            await networkMarketing.save();
            console.log('Başvuru başarıyla kaydedildi');
            setSubmitted(true);
        } catch (error) {
            console.error('Başvuru kaydedilirken hata oluştu:', error);
        }
    };

    return (
        <StyledModal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="p-5">
                {!submitted ? (
                    <>
                        <Title>MFT-Wachstumsziel</Title>
                        <Description>
                            Durch den Beitritt zum MFT-Netzwerk-Team können Sie sowohl verdienen als auch andere verdienen lassen. Sie können basierend auf der Menge an MFT verdienen, die jede Person, die Sie in Ihr Team aufnehmen, einbringt. Bewerben Sie sich und warten Sie ab...
                        </Description>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Vorname"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="rounded-pill border"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Nachname"
                                    name="surname"
                                    value={formData.surname}
                                    onChange={handleChange}
                                    required
                                    className="rounded-pill border"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="E-Mail"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="rounded-pill border"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <PhoneInput
                                    country={'de'}
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        className: 'form-control rounded-pill border'
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="select"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    required
                                    className="rounded-pill border"
                                >
                                    <option value="">Land auswählen</option>
                                    {countryList.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <div className="text-center">
                                <Button type="submit" className="btn-primary rounded-pill px-4">
                                    Bewerben
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <SuccessMessage>Ihre Bewerbung wurde erfolgreich eingereicht.</SuccessMessage>
                        <Description>
                        Ihre Bewerbung wird überprüft. Sobald Ihr Konto genehmigt wurde, erhalten Sie eine E-Mail mit weiteren Informationen zur Aufnahme in unser Network-Marketing-System.
                        </Description>
                    </>
                )}
            </Modal.Body>
        </StyledModal>
    );
};

export default NetworkMarketingModal;