import React, { useState, useEffect, useRef } from 'react';
import { OpenAI } from 'openai';
import { RiMessageLine, RiCloseLine, RiLoader4Line, RiFullscreenLine, RiFullscreenExitLine } from 'react-icons/ri';

// CSS dosyası
import './ChatComponent.css';

const ChatComponent = ({ isOpen, toggleChat }) => {

    const apiKey = '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'; // API anahtarınız
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null); // Mesajların sonuna odaklanmak için ref
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setMessages([{ text: "Hallo, MyFutureToken AI. Wie kann ich dir helfen?", sender: 'bot' }]);
    }, []);

    useEffect(() => {
        if (isOpen) {
            scrollToBottom();
        }
    }, [messages]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };


    const sendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = { text: input, sender: 'user' };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput(''); // Input sıfırla
        setIsLoading(true); // Yükleme göstergesini başlat

        const botResponse = await getBotResponse(input);
        setMessages((prevMessages) => [...prevMessages, { text: botResponse, sender: 'bot' }]);

        setInput('');
        setIsLoading(false); // Yükleme göstergesini durdur

    };

    const getBotResponse = async (inputText) => {
        // OpenAI API entegrasyonunu bu sefer doğru metodu kullanarak güncelleyelim
        try {
            const completion = await openai.chat.completions.create({
                model: "gpt-4",
                messages: [{
                    role: "system",
                    content: "Bu bot, MyFutureToken hakkında bilgi sağlar , Sadece İngilizce veya Almanca cevap verir. Kesinlikle! Yazılan metnin diline göre cevap verilmelidir. MyFutureToken, sosyal medyada güçlü bir varlık oluşturmayı amaçlar ve kullanıcıların öncü olmalarını teşvik eder. Kullanıcılar arkadaşlarını davet ederek her yeni kullanıcı başına alınan token miktarının %10'unu bonus olarak kazanabilirler. MyFutureToken aynı zamanda sosyal medya platformlarında ödüllendirme ve etkileşim aracı olarak kullanılabilir. Bot, MyFutureToken dışındaki konularda bilgi sağlamaz ve bu tür sorulara 'Bu konu hakkında bilgi sağlamamın dışında.' şeklinde yanıt verir."
                }, {
                    role: "user",
                    content: inputText
                }]
            });

            const result = completion.choices[0].message.content;
            return result;
        } catch (error) {
            console.error('OpenAI ile iletişimde hata:', error);
            return 'Şu anda cevap veremiyorum, lütfen daha sonra tekrar deneyin.';
        }
    };

    const chatContainerStyle = isOpen ? {
        display: 'flex', // or 'block' depending on your layout needs
        flexDirection: 'column',
        width: isExpanded ? '50%' : '300px', // Genişletilmiş veya standart genişlik
        height: isExpanded ? '50vh' : '500px', // Genişletilmiş veya standart yükseklik
        position: 'fixed',
        bottom: '60px', // Height of the button plus some margin
        right: '20px',
        zIndex: 1000, // Make sure it's above other elements
    } : {
        display: 'none',
    };


    return (

        <div className={`chat-container ${isOpen ? 'open' : ''}`} style={chatContainerStyle}>
            <div className="chat-header">
                Chat with our AI Bot!
                <button onClick={toggleExpand} className="expand-chat">
                    {isExpanded ? <RiFullscreenExitLine /> : <RiFullscreenLine />}
                </button>

                <button onClick={toggleChat} className="close-chat">X</button>
            </div>
            <div className="chat-body">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                        {message.text}
                        <div ref={messagesEndRef} />
                    </div>

                ))}
            </div>
            <div className="chat-footer">
                <input
                    type="text"
                    placeholder="Schreibe etwas"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button onClick={sendMessage} disabled={isLoading}>
                    {isLoading ? <RiLoader4Line className="loader-icon" /> : "Senden"}
                </button>
            </div>
        </div>
    );
};

export default ChatComponent;
