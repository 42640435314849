import React, { useState } from "react";
import LayoutTwo from "../../layouts/LayoutTwo";
import Step from "../../components/Step/Step";
import metamask1 from "../../assets/img/metamaskguideline/metamask1.jpg";
import metamask2 from "../../assets/img/metamaskguideline/metamask2.jpg";
import metamask3 from "../../assets/img/metamaskguideline/metamask3.jpg";
import metamask4 from "../../assets/img/metamaskguideline/metamask4.jpg";
import metamask5 from "../../assets/img/metamaskguideline/metamask5.jpg";
import metamask6 from "../../assets/img/metamaskguideline/metamask6.jpg";
import metamask7 from "../../assets/img/metamaskguideline/metamask7.jpg";
import metamaskBackupVerify from "../../assets/img/metamaskguideline/metamaskBackupVerify.jpg";
import metamaskpassword from "../../assets/img/metamaskguideline/metamaskpassword.jpg";

const steps = {
  ENG: [
    {
      title: "Download MetaMask",
      description: "First, visit <a href='https://metamask.io/' target='_blank'>MetaMask</a> and click 'Download'. Then click 'Install MetaMask for Chrome'.",
      image: metamask1,
    },
    {
      title: "Install MetaMask",
      description: "You'll be redirected to the Chrome Web Store. Click 'Add to Chrome' and then 'Add Extension' to install the MetaMask extension.",
      image: metamask2,
    },
    {
      title: "Access MetaMask Extension",
      description: "Once installed, you can find the extension by clicking the puzzle piece icon in the top right corner of your browser. Pin the extension for easier access.",
      image: metamask3,
    },
    {
      title: "Create a Wallet",
      description: "Open the MetaMask extension and select 'Create a Wallet'. If you don't want to share analytics data, click 'No Thanks'.",
      image: metamask5,
    },
    {
      title: "Create a Password",
      description: "Create a password to lock the MetaMask extension on your computer. Enter your password and click 'Create'.",
      image: metamaskpassword,
    },
    {
      title: "Secret Backup Phrase",
      description: "Watch the informational video about how MetaMask works. Then, you'll see your Secret Backup Phrase. Store it in a safe place.",
      image: metamask6,
    },
    {
      title: "Backup and Verify Secret Backup Phrase",
      description: "Backup your Secret Backup Phrase and verify it on the next screen. If you lose this phrase, you lose access to your wallet. If someone else gets it, they can access your wallet.",
      image: metamaskBackupVerify,
    },
    {
      title: "Find Your Ethereum Address",
      description: "Open your wallet by clicking the fox icon in the top right corner. Copy the '0x...' address to find your Ethereum address.",
      image: metamask7,
    },
  ],
  GER: [
    {
      title: "MetaMask herunterladen",
      description: "Besuchen Sie zuerst <a href='https://metamask.io/' target='_blank'>MetaMask</a> und klicken Sie auf 'Download'. Klicken Sie dann auf 'Install MetaMask for Chrome'.",
      image: metamask1,
    },
    {
      title: "MetaMask installieren",
      description: "Sie werden zum Chrome Web Store weitergeleitet. Klicken Sie auf 'Zu Chrome hinzufügen' und dann auf 'Erweiterung hinzufügen', um die MetaMask-Erweiterung zu installieren.",
      image: metamask2,
    },
    {
      title: "MetaMask-Erweiterung aufrufen",
      description: "Nach der Installation finden Sie die Erweiterung, indem Sie auf das Puzzleteil-Symbol in der oberen rechten Ecke Ihres Browsers klicken. Heften Sie die Erweiterung für einen einfacheren Zugriff an.",
      image: metamask3,
    },
    {
      title: "Eine Wallet erstellen",
      description: "Öffnen Sie die MetaMask-Erweiterung und wählen Sie 'Create a Wallet'. Wenn Sie keine Analysedaten teilen möchten, klicken Sie auf 'No Thanks'.",
      image: metamask5,
    },
    {
      title: "Ein Passwort erstellen",
      description: "Erstellen Sie ein Passwort, um die MetaMask-Erweiterung auf Ihrem Computer zu sperren. Geben Sie Ihr Passwort ein und klicken Sie auf 'Create'.",
      image: metamaskpassword,
    },
    {
      title: "Geheime Sicherungsphrase",
      description: "Sehen Sie sich das Informationsvideo an, wie MetaMask funktioniert. Dann sehen Sie Ihre geheime Sicherungsphrase. Bewahren Sie sie an einem sicheren Ort auf.",
      image: metamask6,
    },
    {
      title: "Geheime Sicherungsphrase sichern und überprüfen",
      description: "Sichern Sie Ihre geheime Sicherungsphrase und überprüfen Sie sie auf dem nächsten Bildschirm. Wenn Sie diese Phrase verlieren, verlieren Sie den Zugriff auf Ihre Wallet. Wenn jemand anderes sie bekommt, kann er auf Ihre Wallet zugreifen.",
      image: metamaskBackupVerify,
    },
    {
      title: "Ihre Ethereum-Adresse finden",
      description: "Öffnen Sie Ihre Wallet, indem Sie auf das Fuchs-Symbol in der oberen rechten Ecke klicken. Kopieren Sie die '0x...' Adresse, um Ihre Ethereum-Adresse zu finden.",
      image: metamask7,
    },
  ],
};

const HomeThree = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [language, setLanguage] = useState(null);

  const handleNext = () => {
    if (currentStep < steps[language].length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setCurrentStep(0);
  };

  return (
    <LayoutTwo>
      <main style={{ backgroundColor: "#030b15", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div style={{ margin: "100px 0", textAlign: "center" }}>
          {language === null ? (
            <div>
              <h2>Select Language</h2>
              <button
                onClick={() => handleLanguageSelect('ENG')}
                style={{
                  padding: "10px 20px",
                  margin: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                English
              </button>
              <button
                onClick={() => handleLanguageSelect('GER')}
                style={{
                  padding: "10px 20px",
                  margin: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Deutsch
              </button>
            </div>
          ) : (
            <Step
              stepNumber={currentStep + 1}
              title={steps[language][currentStep].title}
              description={steps[language][currentStep].description}
              image={steps[language][currentStep].image}
              onNext={handleNext}
              onPrevious={handlePrevious}
              isLastStep={currentStep === steps[language].length - 1}
            />
          )}
        </div>
      </main>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
    </LayoutTwo>
  );
};

export default HomeThree;