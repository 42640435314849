import React, { createContext, useContext, useState } from 'react';

const InvitationContext = createContext();

export const InvitationProvider = ({ children }) => {
  const [isInvited, setIsInvited] = useState(false);
  const [invitationCode, setInvitationCode] = useState(null);
  const [inviterName, setInviterName] = useState('');

  return (
    <InvitationContext.Provider 
      value={{ 
        isInvited, 
        setIsInvited, 
        invitationCode, 
        setInvitationCode,
        inviterName,
        setInviterName 
      }}
    >
      {children}
    </InvitationContext.Provider>
  );
};

export const useInvitation = () => useContext(InvitationContext);
