import React, { useState } from "react";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import HeaderTwo from "../components/Header/HeaderTwo";
import FooterTwo from "../components/Footer/FooterTwo";
import ChatComponent from "../components/ChatBot/ChatBot";
import { RiMessageLine, RiCloseLine } from "react-icons/ri"; // Using React Icons for chat and close icons

const LayoutTwo = (props) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  return (
    <div className={cn("white-background")}>
      <PageLoader />

      <HeaderTwo />

      {props.children}
      <button 
        onClick={toggleChat} 
        style={{
          position: 'fixed', 
          right: '20px', 
          bottom: '20px', 
          backgroundColor: 'blue', 
          borderRadius: '50%', 
          width: '50px', 
          height: '50px', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          border: 'none'
        }}
      >
        {isChatOpen ? <RiCloseLine color="white" /> : <RiMessageLine color="white" />}
      </button>
      {isChatOpen && <ChatComponent isOpen={isChatOpen} toggleChat={toggleChat} />}


      <FooterTwo />
    </div>
  );
};

export default LayoutTwo;
