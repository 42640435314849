import React from "react";
import FaqItem from "./FaqItem";
import Parse from "parse"; // Parse'ı import etmeyi unutmayın
import { useEffect, useState } from "react"; 


const Faq = () => {

  const [faqQuestion1, setfaqQuestion1] = useState("");
	const [faqQuestion2, setfaqQuestion2 ] = useState("");
	const [faqQuestion3, setfaqQuestion3] = useState("");
	const [faqQuestion4, setfaqQuestion4] = useState(""); // Güncellemeleri takip etmek için
	const [faqQuestion5, setfaqQuestion5] = useState(""); // Güncellemeleri takip etmek için

	const [faqAnswer1, setfaqAnswer1] = useState("");
	const [faqAnswer2, setfaqAnswer2] = useState(""); // Güncellemeleri takip etmek için
	const [faqAnswer3, setfaqAnswer3] = useState(""); // Güncellemeleri takip etmek için
	const [faqAnswer4, setfaqAnswer4] = useState(""); // Güncellemeleri takip etmek için
	const [faqAnswer5, setfaqAnswer5] = useState(""); // Güncellemeleri takip etmek için

  
  useEffect(() => {
    // Parse sorgusu ile myfacecoin objesinden aboutTitle çekme
    const MyFaceCoin = Parse.Object.extend("myfacecoin");
    const query = new Parse.Query(MyFaceCoin);
    // Eğer belirli bir objeyi çekmek istiyorsanız, objectId ile sorgulama yapabilirsiniz
    // query.equalTo("objectId", "YOUR_OBJECT_ID_HERE");
    query.first().then((object) => {
      // Sorgu başarılı olduğunda aboutTitle state'ini güncelle
      if (object) {
        setfaqQuestion1(object.get("faqQuestion1"));
        setfaqQuestion2(object.get("faqQuestion2"));
        setfaqQuestion3(object.get("faqQuestion3"));
        setfaqQuestion4(object.get("faqQuestion4"));
        setfaqQuestion5(object.get("faqQuestion5"));
  
        setfaqAnswer1(object.get("faqAnswer1"));
        setfaqAnswer2(object.get("faqAnswer2"));
        setfaqAnswer3(object.get("faqAnswer3"));
        setfaqAnswer4(object.get("faqAnswer4"));
        setfaqAnswer5(object.get("faqAnswer5"));
        } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);


  
  const faq_items = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: faqQuestion1 ,
      details: faqAnswer1,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: faqQuestion2,
      details: faqAnswer2,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: faqQuestion3,
      details: faqAnswer3,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: faqQuestion4,
      details: faqAnswer4,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: faqQuestion5,
      details: faqAnswer5,
    },
  ];


  
  return (
    <section id="faq" className="faq-area">
      <div className="container custom-container-four">
        <div className="faq-shape-wrap">
          {/* <img src={shape01} alt="" className="img-one" />
          <img src={shape02} alt="" className="img-two rotateme" />
          <img src={shape03} alt="" className="img-three" /> */}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title">Häufig gestellte Fragen</h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
