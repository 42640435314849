import React from "react";
import ContactTwoForm from "./ContactTwoForm";
import ContactTwoInfo from "./ContactTwoInfo";
import Parse from "parse"; // Parse'ı import etmeyi unutmayın
import { useEffect, useState } from "react";
import "./contact.css"

const ContactTwo = () => {
  const [formMode, setFormMode] = useState('register'); // 'register' veya 'login' olabilir
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: ''
  });
  const [agreementChecked, setAgreementChecked] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setAgreementChecked(e.target.checked);
  };

  const handleFormSwitch = () => {
    setFormMode(formMode === 'register' ? 'login' : 'register');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formMode === 'register') {
      if (agreementChecked) {
        try {
          // Parse kullanarak yeni bir kullanıcı kaydı
          const user = new Parse.User();
          user.set('username', formData.email);
          user.set('email', formData.email);
          user.set('password', formData.password);
          user.set('name', formData.name);
          user.set('surname', formData.surname);

          await user.signUp();
          console.log('User registered successfully');
          // Burada kullanıcı arayüzüne başarılı kayıt olduğuna dair geri bildirim sağlayabilirsiniz.
        } catch (error) {
          console.error('Error while registering user', error);
          // Burada hata mesajını kullanıcı arayüzüne göstermek için bir durum oluşturabilirsiniz.
        }
      } else {
        // Kullanıcı gizlilik anlaşmasını kabul etmediyse bir hata mesajı gösterin
        console.error('You must agree to the terms and conditions');
      }
    } else if (formMode === 'login') {
      try {
        // Parse kullanarak kullanıcı girişi
        const user = await Parse.User.logIn(formData.email, formData.password);
        console.log('User logged in successfully', user);
        // Burada kullanıcı arayüzüne başarılı giriş yaptığına dair geri bildirim sağlayabilirsiniz.
      } catch (error) {
        console.error('Error while logging in user', error);
        // Burada hata mesajını kullanıcı arayüzüne göstermek için bir durum oluşturabilirsiniz.
      }
    }
  };

  
  return (
    <section id="contact" className="contact-aera contact-bg">

    <div className="contact-form-wrap-two wow fadeInRight" data-wow-delay=".2s">
    <h2 className="title">{formMode === 'register' ? 'Registrieren Sie sich bei uns' : 'Anmeldung'}</h2>
    <form onSubmit={handleSubmit}>
      {formMode === 'register' && (
        <>
          <input
            type="text"
            name="name"
            placeholder="Vorname"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="surname"
            placeholder="Name"
            value={formData.surname}
            onChange={handleInputChange}
            required
          />
        </>
      )}
      <input
        type="email"
        name="email"
        placeholder="E-mail"
        value={formData.email}
        onChange={handleInputChange}
        required
      />
      <input
        type="password"
        name="password"
        placeholder="Passwort"
        value={formData.password}
        onChange={handleInputChange}
        required
      />
      {formMode === 'register' && (
        <div className="checkbox-wrap">
          <input
            type="checkbox"
            id="agreement"
            checked={agreementChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="agreement">
          Ich akzeptiere die <a href="/terms">Servicebedingungen</a> und die <a href="/privacy">Datenschutzrichtlinie</a>
          </label>
        </div>
      )}
      
      <button type="submit" className="btn btn-two">
        {formMode === 'register' ? 'Registrieren' : 'Anmeldung'}
      </button>
    </form>
    <center>
    <button onClick={handleFormSwitch} className="btn btn-switch">
      {formMode === 'register' ? 'Anmeldung' : 'Registrieren'}
    </button></center>
  </div>
  </section>
  );
};

export default ContactTwo;
